import ContactDialog from './ContactDialog'
import links, { Link } from './links'
import MobileMenu from './MobileMenu'

function Logo() {
    return (
        <a
            href="/"
            className="flex items-center space-x-2 rounded p-1 transition-colors hover:bg-primary-300 focus:outline-none focus:ring-2 focus:ring-primary-500"
        >
            <img
                src="/logo.png"
                alt="Logo de RégioLangues"
                className="h-10 w-10 rounded-lg"
            />
            <span className="hidden font-serif text-lg font-semibold sm:block sm:text-2xl">
                RégioLangues
            </span>
        </a>
    )
}

function NavLink({ href, name, icon, onClick }: Link) {
    const C = href !== undefined ? 'a' : 'button'
    return (
        <C
            key={name}
            href={href}
            onClick={onClick ? () => onClick() : undefined}
            className="group inline-flex items-center space-x-1 rounded px-4 py-2 text-base text-neutral-700 transition-colors hover:bg-primary-300 hover:text-neutral-900 focus:outline-none focus:ring-2 focus:ring-primary-500 lg:text-lg"
        >
            {icon}
            <span>{name}</span>
        </C>
    )
}

function FacebookLink() {
    return (
        <a
            href="https://www.facebook.com/Régiolangues-104808331988343"
            target="_blank"
            className="group rounded-full p-1 text-neutral-600 transition-colors hover:text-[#1877F2] focus:text-[#1877F2] focus:outline-none focus:ring-2 focus:ring-[#1877F2] focus:ring-offset-2 focus:ring-offset-primary-200"
        >
            <span className="sr-only">Facebook</span>
            <svg
                role="img"
                viewBox="0 0 24 24"
                fill="currentColor"
                stroke="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 rounded-full group-hover:bg-white group-focus:bg-white"
                aria-hidden="true"
            >
                <title>Facebook</title>
                <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"></path>
            </svg>
        </a>
    )
}

export default function Header() {
    return (
        <>
            <header className="bg-primary-200">
                <nav className="custom-container mx-auto flex items-center justify-between px-4 py-2">
                    <Logo />
                    <div className="flex items-center space-x-2">
                        <div className="hidden items-center md:flex">
                            {links().map((link, i) => (
                                <NavLink key={i} {...link} />
                            ))}
                        </div>
                        <FacebookLink />
                        <MobileMenu
                            classNames={{
                                menu: 'origin-top-right right-0 md:hidden',
                                button: 'md:hidden',
                            }}
                        />
                    </div>
                </nav>
            </header>
            <ContactDialog />
        </>
    )
}
